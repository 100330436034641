import ActionTypes from "./../action/action";
const initialState = {
  loggedIn: false,
  authUser: {},
  authToken: "",
  name: "Super Admin"
};

const finaljson= (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_USER:
      return {
        ...state,
        authUser: action.payload && action.payload.user_data || {},
        loggedIn: true,
        authToken: action.payload && action.payload.token || ""
      };
    case ActionTypes.LOGOUT_USER:
      return {
        ...initialState
      };
      case ActionTypes.SET_LOCAL_MMV:
      return {
        ...state,
        localMMV: action.componentState
      };
    case ActionTypes.UPDATE_CURRENT_PATH:
      return ({
          ...state,
          currentPath: action.payload.currentPath
      });
    default:
      return state;
  }
};

export default finaljson;