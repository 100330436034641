const ActionTypes = {
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  GET_CONFIG_DATA:"GET_CONFIG_DATA",
  SET_LOCAL_MMV:"SET_LOCAL_MMV",
  UPDATE_CURRENT_PATH:'UPDATE_CURRENT_PATH'
};
export const updateCurrentPath =(data)=>{
  // console.log("dispatched data", data)
  return {
      type:'UPDATE_CURRENT_PATH',
      payload:data
  }
}
export default ActionTypes;
