
const commonReducer = (state = '', action) => {
    switch (action.type) {
        case "GET_CONFIG_DATA":  
            return  {
                ...state, 
                configData:action.payload
            }
        case "STATE_CITY_LIST":  
            return  {
                ...state, 
                stateCity:action.payload
            }      
             
        default:
            return state
    }
}

export default commonReducer;
