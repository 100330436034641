import InterceptorService from "./InterceptorService";
const finaljson={
    ...InterceptorService,
    getStateCity() {
        return this.get("https://int-gcloud-stage.oto.com/core/commonservice/state_city_all");
    },
    getLocalityList(filter_obj) {
        return this.post("https://int-gcloud-stage.oto.com/core/commonservice/locality_list", filter_obj);
    },
    getAllMmv(queryParams) {
        return this.get("core/commonservice/mmv_all"+queryParams);
    },
    StateCityList() {
        return this.get("core/commonservice/state_city_all");
    },
    LocalityList(filter_obj) {
        return this.post("core/commonservice/locality_list", filter_obj);
    },
    getDealerList() {
        let headers = {};
        headers["apikey"] = "e8fe3567-00cd-4121-b34b-495c797526a4";
        return this.get("dealer/dealer/list-all", {}, headers);
    }    
};

export default finaljson;