import { toast } from "react-toastify"
const finaljson = (message = 'Success', status = true) => {
    if (status) {
        toast.success(message)
    }
    else {
        toast.error(message)
    }
}

export default finaljson;