import allConf from "./config";
const conf = allConf[process.env.REACT_APP_CONFIG_FILE_NAME] ? allConf[process.env.REACT_APP_CONFIG_FILE_NAME] : allConf.demo;
export const API_URL = {
    GETWAY_API: process.env.REACT_APP_GETWAY_API
}
export const APIV = 2;
export const LINK_URL = {
    LOGO: conf.LOGO,
    GOOGLE_PLAY: conf.GOOGLE_PLAY,
    APP_STORE: conf.APP_STORE,
    WHATSAPP_SHARE: conf.ALLOW_WHATSAPP_SHARE
};
export const DOCUMENT_ID = {
    FIRST_SUBMIT_DEFAULT_DOCUMENT_CATEGORY: 17,
    FIRST_SUBMIT_DEFAULT_DOCUMENT_SUB_CATEGORY: 1,
    DISBURSAL_DEFAULT_DOCUMENT_CATEGORY: 21,
    DISBURSAL_DEFAULT_DOCUMENT_SUB_CATEGORY: 22
};
export const MOBILE_VALIDATION=new RegExp(conf.MOBILE_VALIDATION);
export const FE_STATUS_ID= [6];
export const LOAN_DISBURSE_STATUS_ID= [16];
export const SENT_TO_FINANCIER_STATUS_ID= 7;
export const CURRENCY_SYMBOL= "Rp. ";
export const CURRENCY_SEPARATOR= ".";
export const INCENTIVE_CALCULATION_MENU_ID= 23;
export const FF_STATUS_SUB_MENU= 8;
export const LOAN_APPROVE_DOC_PARENT_ID = 31;
export const FIELD_MAP_WITH_DOCUMENT = {
    'customer_name': 17,
    'mobile_number': 17,
    'bpkb_name': 17,
    'bpkb_ownership': 17,
    'id_card_number': 17,
    'npwp_number': 17,
    'birth_place_id': 17,
    'dob': 17,
    'religion_id': 17,
    'mother_name': 19,
    'marital_status_id': 17,
    'couple_name': 19,
    'couple_id_number': 19,
    'couple_birth_place_id': 19,
    'couple_dob': 19,
    'current_address':17,
    'rt':17,
    'rw':17,
    'state_id':17,
    'city_id':17,
    'locality_id':17,
    'sub_district':17,
    'postal_code':17,
    'c_current_address':17,
    'c_rt':17,
    'c_rw':17,
    'c_state_id':17,
    'c_city_id':17,
    'c_locality_id':17,
    'c_sub_district':17,
    'c_postal_code':17,
    'loan_amount_transferred':31,
    'tenure':31
};
export const CSUL_FINANCER_ID= 6;
export const SINARMAS_FINANCE_ID=7;
export const SUB_STATUS_ID_OF_INCENTIVE_APPROVE = 24;
export const STATUS_ID_OF_LOST = 27;
export const SUB_STATUS_ID_OF_INCENTIVE_APPROVAL_RECEIVED = 37;
export const SUB_STATUS_ID_OF_LOST_PB = 38;
export const ACC_FINANCER_ID= 14;

export const DOCUMENT_CATEGORY={
    FIRST_SUBMIT:{
        "KTP ID":{
            document_id:1,
            document_parent_id:0
        },
        "STNK":{
            document_id:2,
            document_parent_id:0
        },
        "Vehicle Image":{
            document_id:2,
            document_parent_id:0
        },
        "Calculator Photo":{
            document_id:3,
            document_parent_id:0
        },
        "Go Live Proof":{
            document_id:29,
            document_parent_id:0
        }
    },
    DISBURSAL:{
        "Loan Approved":{
            document_id:13,
            document_parent_id:0
        },
        "SPT":{
            document_id:14,
            document_parent_id:0
        },
        "Confirmation Letter":{
            document_id:15,
            document_parent_id:0
        },
        "NPWP":{
            document_id:16,
            document_parent_id:0
        },
        "Calculator Photo":{
            document_id:17,
            document_parent_id:0
        },
        "Deviation Docs":{
            document_id:24,
            document_parent_id:0
        }
    }
}

export const DOCUMENT_SUB_CATEGORY={
    FIRST_SUBMIT:{
        "ID Card":{
            document_id:5,
            document_parent_id:1
        },
        "Registration Card":{
            document_id:6,
            document_parent_id:2
        },
        "Front View":{
            document_id:7,
            document_parent_id:3
        },
        "Rear View":{
            document_id:9,
            document_parent_id:3
        },
        "Left Side view":{
            document_id:10,
            document_parent_id:3
        },
        "Right Side view":{
            document_id:11,
            document_parent_id:3
        },
        "Interior Photo":{
            document_id:12,
            document_parent_id:3
        },
        "Calculator":{
            document_id:5,
            document_parent_id:4
        },
        "Live Proof Doc":{
            document_id:30,
            document_parent_id:29
        }
    },
    DISBURSAL:{
        "Loan":{
            document_id:18,
            document_parent_id:13
        },
        "SPT Dealer":{
            document_id:19,
            document_parent_id:14
        },
        "SPT Agent":{
            document_id:20,
            document_parent_id:14
        },
        "Confirmation":{
            document_id:21,
            document_parent_id:15
        },
        "NPWP":{
            document_id:22,
            document_parent_id:16
        },
        "Calculator":{
            document_id:23,
            document_parent_id:17
        },
        "Incentive Calculation":{
            document_id:25,
            document_parent_id:24
        },
        "Refund Docs":{
            document_id:26,
            document_parent_id:24
        },
        "Refund Transfer to OTO":{
            document_id:27,
            document_parent_id:24
        },
        "Calculator Photo":{
            document_id:28,
            document_parent_id:24
        }
    }
}
export const SEARCH_TYPE_OPTIONS = [
    {
        value: "loan_id",
        label: "Loan ID"
    },
    {
        value: "cust_name",
        label: "Customer Name"
    },
    {
        value: "cust_mobile_number",
        label: "Customer Mobile Number"
    },
    {
        value: "plate_number",
        label: "Plate Number"
    }
]

export const LOAN_BOX_USERS = {
    ROLES:{
        SUPER_ADMIN:{
            ID:'-1',
            FEATURES_REFINANCE_SUB_STATUS: ['all']
        },
        ADMIN:{
            ID:'2',
            FEATURES_REFINANCE_SUB_STATUS: ['all']
        },
        LEAD_MANAGEMENT: {
            ID:'23',
            FEATURES_REFINANCE_SUB_STATUS: [2,3,5,6,36,10,11,12,13,14,16]
        },
        DO_MAKER: {
            ID:'24',
            FEATURES_REFINANCE_SUB_STATUS: [17],
            FEATURES_REFINANCE_ACTIONS: ['ubrf_disbursal_to_customer']
        },
        DO_CHECKER: {
            ID:'25',
            FEATURES_REFINANCE_SUB_STATUS: [19,22]
        },
        OPS_SUPPORT: {
            ID:'27',
            FEATURES_REFINANCE_SUB_STATUS: []
        },
        BACKUP_TEAM: {
            ID:'26',
            FEATURES_REFINANCE_SUB_STATUS: [23,24,37,39]
        },
    }
}
export const OPEN_TAB_REFINANCE_SUB_STATUS_LEAD_MANAGEMENT=[36,10,11,12,13,14,16]
export const OPEN_TAB_REFINANCE_SUB_STATUS_BACKUP_TEAM=[24,37]
export const OPEN_TAB_REFINANCE_SUB_STATUS=[36,10,11,12,13,14,16,24,37,23,25,39]
export const NON_OPEN_TAB_REFINANCE_SUB_STATUS=[2,3,5,6,17,19,22]
export const DISBURSED_TAB_REFINANCE_SUB_STATUS=[20,26]
export const MY_CASE_ROLE_IDS = ['23','24','25']
export const BM_ADVANCE_PAYMENT_LIMIT_DEFAULT_SCHEME_ID = 1;
export const DEALER_ADVANCE_PAYMENT_LIMIT_DEFAULT_SCHEME_ID = 2;

export const FINANCIER_SPECIFIC_DOCS_PARENT_ID = [31, 4]

export const API_TIMEOUT = 15000;

export const REQUEST_RESPONSE_ENCRYPTION = {
    aes_algo: "aes-256-cbc",
    response_aes_key: "770A8A65DA156D24EE2A093277530142",
    public_key: "-----BEGIN PUBLIC KEY-----\nMIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDG2koJfj4vF4UkiSl9vnmznE31\nMV7wslypRbwHEbN0KL4YtpH42euTfTEpD3RuyC3+QG4jHAmtyn+jxQJsZN8/Tq4L\n4N+tp83PZDQoazJDBVDtBCx5Rsyz9P0xemJkbfhbY4LRwQojDYLBlNRI4LHuBnnf\n1sL3PU9daSNQud+GBQIDAQAB\n-----END PUBLIC KEY-----\n",
}
export const BM_ROLE_ID = 52;
export const ADDITIONAL_DOC_CHILD_ID = [57];
export const INCENTIVE_STATUS_ID= 21;
export const INCENTIVE_APPROVAL_RECEIVED_SUB_STATUS_ID= 37;
export const SHOW_USER_ROLE_NAME = () => {
	if (API_URL.GETWAY_BASE_PATH === 'favourite_id') {
		return {
			AMF: "AMF",
			BM: "MM",
			AM: "RM",
			ZM: "GM",
			RBH: "RBH",
			AGENT_BRO: "FA/PA/MO",
		}
	}else{
		return {
			BM: "BM",
			ZM: "ZM",
			AM: "AM",
			RBH: "RBH",
			AGENT_BRO: "Alpha Agent/Agent/BRO",
		}
	}
};
export const IS_FAVOURITE_LOGIN = () => {	
	return API_URL.GETWAY_BASE_PATH === 'favourite_id';
};