import { createStore, applyMiddleware, combineReducers } from "redux";
import userReducer from "./reducer/userReducer";
import leadReducer from "./reducer/leadReducer";
import commonReducer from "./reducer/commonReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
const mainReducer = combineReducers({
    user: userReducer,
    lead:leadReducer,
    common:commonReducer
});

const commonData = {
    user: {},
    lead:{}
};

const store = createStore(
    mainReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

export default store;