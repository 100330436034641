import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_id from "./translations/id/common.json";
import common_en from "./translations/en/common.json";
import secureStorage from "./config/encrypt";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import more_id from "./translations/id/more.json";
import more_en from "./translations/en/more.json";
import rule_engine_id from "./translations/id/rule-engine.json";
import rule_engine_en from "./translations/en/rule-engine.json";
import login_en from "./translations/en/login.json";
import login_id from "./translations/id/login.json";
import loan_details_en from "./translations/en/loan-details.json";
import loan_details_id from "./translations/id/loan-details.json";
import loan_list_en from "./translations/en/loan-list.json";
import loan_list_id from "./translations/id/loan-list.json";
import common_mmv_en from "./translations/en/common-mmv.json";
import common_mmv_id from "./translations/id/common-mmv.json";
import loanexpected_en from "./translations/en/loanexpected.json";
import loanexpected_id from "./translations/id/loanexpected.json";
import { updateAuthUser } from './store/action/allAction';
import store from "./store/store";
import AllTranslation from './translations/refinance/translation';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import _ from 'lodash';
import {setContext} from '@apollo/client/link/context';
import { toast } from "react-toastify";
import { API_URL } from "./config/constant";
const getEnTranslationJson = (file) => {
	return (AllTranslation[`${file}_${process.env.REACT_APP_CONFIG_FILE_NAME}`]) ?
	  _.merge(AllTranslation[file], AllTranslation[`${file}_${process.env.REACT_APP_CONFIG_FILE_NAME}`]) :
	  AllTranslation[file];
  }
  
	i18next.init({
		interpolation: { escapeValue: false },
		lng: secureStorage.getItem("lang") === "id" ? "id" : "en",
		resources: {
			en: {
				common      : common_en,
				more        : more_en,
				rule_engine : rule_engine_en,
				login       : login_en,
				loan_details: loan_details_en,
				loan_list   : loan_list_en,
				common_mmv  : common_mmv_en,
				loanexpected: loanexpected_en,
				inventory : getEnTranslationJson('inventory_en'),
				dealers   : getEnTranslationJson('dealer_en'),
				lead      : getEnTranslationJson('lead_en'),
				ac_manager: getEnTranslationJson('ac_manager_en')
			},
			id: {
				common      : common_id,
				more        : more_id,
				rule_engine : rule_engine_id,
				login       : login_id,
				loan_details: loan_details_id,
				loan_list   : loan_list_id,
				common_mmv  : common_mmv_id,
				loanexpected: loanexpected_id,
				inventory : AllTranslation.inventory_id,
				dealers   : AllTranslation.dealer_id,
				lead      : AllTranslation.lead_id,
				ac_manager: AllTranslation.ac_manager_id
			},
		},
	});
	
  //}


let loginInfo = secureStorage.getItem("loginUserInfo");
let authToken = secureStorage.getItem("authToken");
if(authToken && loginInfo && loginInfo.data && loginInfo.data.user_data) store.dispatch(updateAuthUser({user_data:loginInfo.data.user_data || {},token:authToken}));


const httpLink = createHttpLink({
	uri: `${API_URL.GETWAY_API}dif-revamp/api/v1`,
	fetch: (uri, options) => {
		const timeout = 30000;
		return new Promise((resolve, reject) => {
		  const timer = setTimeout(() => {
			reject(new Error('Request timeout'));
		  }, timeout);
		  fetch(uri, options)
			.then((response) => {
			  clearTimeout(timer);
			  resolve(response);
			})
			.catch((error) => {
			  clearTimeout(timer);
			  reject(error);
			});
		});
	  },
});

const authLink = setContext((_, { headers }) => {

	let fetchHeader = (secureStorage.getItem('loginUserInfo'))
	? secureStorage.getItem('loginUserInfo')
	: {};
const token2send= (fetchHeader && fetchHeader.data && fetchHeader.data.token)
	? fetchHeader.data.token : null;

	let headers2send = Object.assign({api_source:'loanbox'},headers);
	if(token2send) {
		headers2send.Authorization = `${token2send}`;
	}else{
		//headers2send.apikey = API_KEY;
	}
	return {
	  headers: headers2send
	}
  });


  const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore',
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all',
	},
}

const client = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache(),
	defaultOptions: defaultOptions
  });


ReactDOM.render(
	<I18nextProvider i18n={i18next}>
		<Provider store={store}>
		<ApolloProvider client={client}>
		  <BrowserRouter>
			<App />
		  </BrowserRouter>
		  </ApolloProvider>
		</Provider>
	  </I18nextProvider>,
	  document.getElementById("root")
	);
  

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();