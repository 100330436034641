import { GeneralService,CommonService } from "../../services";
import crypto from "../../config/crypto";
export const updateAuthUser = (data) => {
    return {
        type: 'LOGIN_USER',
        payload: data
    }
}

export const logout = (data) => {
    return {
        type: 'LOGOUT_USER',
        payload: data
    }
}

export const getConfigData = (postData) => {
    return async (dispatch) => {
		try {
			const { status, data } = await GeneralService.getConfig(
				postData
			);
            if (status === 200 && data.status === 200) {
				const config_data = data.data;
				dispatch(success(config_data));
			} else dispatch(success({}));
		} catch (err) {
			dispatch(success({}));
		}
	};
    function success(payload) {
		return { type: "GET_CONFIG_DATA", payload };
	}
	
}

export const getStateCity = () => {
    return async (dispatch) => {
		try {
			const response = await CommonService.getStateCity();
            if (response && response.status === 200 && response.data.status === 200) {
				const {data} = response.data;
				dispatch(success(data));
			} else dispatch(success({}));
		} catch (err) {
			dispatch(success({}));
		}
	};
    function success(payload) {
		return { type: "STATE_CITY_LIST", payload };
	}
	
}

export const getLoanSummary = (postData) => {
    return async (dispatch) => {
		try {
			const response = await GeneralService.getLoanSummary(postData);
			if (response.status === 200 && response.data.status === 200) {
				let { data } = response.data;
				let summaryData = data.loanSummary;
				// if (summaryData.name) summaryData.name = crypto.decode(summaryData.name);
				// if (summaryData.phone) summaryData.phone = crypto.decode(summaryData.phone);
				// if (summaryData.email) summaryData.email = crypto.decode(summaryData.email);
				if (data) {
					dispatch(success(summaryData));
				}
			} else dispatch(success({}));
		} catch (err) {
			dispatch(success({}));
		}
	};
    function success(payload) {
		return { type: "GET_LOAN_SUMMARY", payload };
	}
	
}

export const updateStatus = (postData) => {
	return async (dispatch) => {
		try {
			const {
				data
			} = await GeneralService.updateApplication(postData);
			if (data.status === 200) {
				return {
					status: true,
					response: data.message
				};
			} else
				return {
					status: false,
					response: data.message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}

export const getLeadRemarks = (postData) => {
    return async (dispatch) => {
		try {
			const response = await GeneralService.getLeadRemarks(postData);
			if (response.status === 200 && response.data.status === 200) {
				let { data } = response.data;
				// console.log('data============',data)
				if (data) {
					dispatch(success(data));
				}
			} else dispatch(success([]));
		} catch (err) {
			dispatch(success([]));
		}
	};
    function success(payload) {
		return { type: "GET_LEAD_REMARKS_HISTORY", payload };
	}
	
}

export const saveLeadRemarks = (postData) => {
	return async (dispatch) => {
		try {
			const {
				data
			} = await GeneralService.saveLeadRemarks(postData);
			if (data.status === 200) {
				return {
					status: true,
					response: data.message
				};
			} else
				return {
					status: false,
					response: data.message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}
export const saveImageDownloadLog = (postData) => {
	return async (dispatch) => {
		try {
			const {
				data
			} = await GeneralService.saveImageDownloadLog(postData);
			if (data.status === 200) {
				return {
					status: true,
					response: data.message
				};
			} else
				return {
					status: false,
					response: data.message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}
