
const leadReducer = (state = '', action) => {
    switch (action.type) {
        
        case "GET_LOAN_SUMMARY":
			return {
				...state,
				loanSummary: action.payload,
			};
		case "GET_LEAD_REMARKS_HISTORY":
			return {
				...state,
				remarksHistory: action.payload,
			};   

		default:
            return state
    }
}

export default leadReducer;
