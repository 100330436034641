import { CURRENCY_SYMBOL, CURRENCY_SEPARATOR ,FIELD_MAP_WITH_DOCUMENT} from './../../config/refinance_constant';
import secureStorage from './../../config/encrypt';
import { LOAN_BOX_USERS, MY_CASE_ROLE_IDS, NON_OPEN_TAB_REFINANCE_SUB_STATUS } from '../../config/bike_refinance_constant';
const CommonService = {
    __changeToRupiah(price) {
        var rev = parseInt(price, 10).toString().split("").reverse().join("");
        var rev2 = "";
        for (var i = 0; i < rev.length; i++) {
            rev2 += rev[i];
            if ((i + 1) % 3 === 0 && i !== (rev.length - 1)) {
                rev2 += CURRENCY_SEPARATOR;
            }
        }
        return CURRENCY_SYMBOL + rev2.split("").reverse().join("");
    },
    __numberWithCommaSeperator(num) {
        num = num == null ? "" : "" + num;
        let p = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + CURRENCY_SEPARATOR);
        return p;
    },
    __getDocumentAndSubDocumentIdByField(field_name ,lead_id) {
        var toggleOffLeadId = JSON.parse(secureStorage.getItem("freez_toggle_lead_id"));
        let index = toggleOffLeadId.indexOf(lead_id);
        return index == -1 ? FIELD_MAP_WITH_DOCUMENT[field_name] : 0;
    },
    __isAuthorised(loginUser={},leadDetails=null,feature="",action="") {
        // console.log("loginUser,leadDetails,feature,action",loginUser,leadDetails,feature,action)
        let is_access = false;
        if(loginUser && Object.keys(loginUser).length > 0 && (loginUser['assigned_role_ids']?.includes(LOAN_BOX_USERS.ROLES.ADMIN.ID) || loginUser['role_id'] == parseInt(LOAN_BOX_USERS.ROLES.SUPER_ADMIN.ID))) {
            is_access = true;                        
        }else{
            let featureActionsList = [];
            const userAccessFeatures = secureStorage.getItem("userAccess");
            // console.log("userAccessFeatures======>",userAccessFeatures)
            if(userAccessFeatures && userAccessFeatures.length){
                featureActionsList = userAccessFeatures.filter(f=>f.access_type=='1').map(f => f.action);
            }
            // console.log("featureActionsList====>",featureActionsList)
            if(action=="mark_as_lost"){
                if (featureActionsList.includes(feature)) {
                    if(NON_OPEN_TAB_REFINANCE_SUB_STATUS.includes(leadDetails.refinance_sub_status_id)){
                        if(leadDetails.assigned_to_user_id==loginUser.user_id){
                            is_access = true;
                        }
                    }else if(LOAN_BOX_USERS.ROLES.LEAD_MANAGEMENT.FEATURES_REFINANCE_SUB_STATUS.includes(leadDetails.refinance_sub_status_id)){
                        if(loginUser && loginUser['assigned_role_ids']?.includes(LOAN_BOX_USERS.ROLES.LEAD_MANAGEMENT.ID)){
                            is_access = true;
                        }
                    }else if(LOAN_BOX_USERS.ROLES.BACKUP_TEAM.FEATURES_REFINANCE_SUB_STATUS.includes(leadDetails.refinance_sub_status_id)){
                        if(loginUser && loginUser['assigned_role_ids']?.includes(LOAN_BOX_USERS.ROLES.BACKUP_TEAM.ID)){
                            is_access = true;
                        }
                    }else {
                        is_access = true;
                    }
                }
            }else if(action=="reopen"){
                if (leadDetails.refinance_status_before_lost) {
                    if(LOAN_BOX_USERS.ROLES.LEAD_MANAGEMENT.FEATURES_REFINANCE_SUB_STATUS.includes(leadDetails.refinance_status_before_lost)){
                        if(loginUser && loginUser['assigned_role_ids']?.includes(LOAN_BOX_USERS.ROLES.LEAD_MANAGEMENT.ID) && featureActionsList.includes(feature)){
                            is_access = true;
                        }
                    }else if(LOAN_BOX_USERS.ROLES.DO_MAKER.FEATURES_REFINANCE_SUB_STATUS.includes(leadDetails.refinance_status_before_lost)){
                        if(loginUser && loginUser['assigned_role_ids']?.includes(LOAN_BOX_USERS.ROLES.DO_MAKER.ID) && featureActionsList.includes(feature)){
                            is_access = true;
                        }
                    }else if(LOAN_BOX_USERS.ROLES.DO_CHECKER.FEATURES_REFINANCE_SUB_STATUS.includes(leadDetails.refinance_status_before_lost)){
                        if(loginUser && loginUser['assigned_role_ids']?.includes(LOAN_BOX_USERS.ROLES.DO_CHECKER.ID) && featureActionsList.includes(feature)){
                            is_access = true;
                        }
                    }else if(LOAN_BOX_USERS.ROLES.BACKUP_TEAM.FEATURES_REFINANCE_SUB_STATUS.includes(leadDetails.refinance_status_before_lost)){
                        if(loginUser && loginUser['assigned_role_ids']?.includes(LOAN_BOX_USERS.ROLES.BACKUP_TEAM.ID) && featureActionsList.includes(feature)){
                            is_access = true;
                        }
                    }else{
                        is_access = true;
                    }
                }               
            }else if(action == "user_assignment" || action == "config" || action == "doc_upload" || action == "loan_expected"){
                if (featureActionsList.includes(feature)) {
                    is_access = true;               
                }
            }else{
                if (featureActionsList.includes(feature)) {
                    if(NON_OPEN_TAB_REFINANCE_SUB_STATUS.includes(leadDetails.refinance_sub_status_id)){
                        if(LOAN_BOX_USERS.ROLES.DO_CHECKER.FEATURES_REFINANCE_SUB_STATUS.includes(leadDetails.refinance_sub_status_id) && LOAN_BOX_USERS.ROLES.DO_MAKER.FEATURES_REFINANCE_ACTIONS.includes(feature)){
                            if(leadDetails.disbursal_to_customer_created_by && leadDetails.disbursal_to_customer_created_by == loginUser.user_id){
                                is_access = true;
                            }                              
                        }else if(leadDetails?.assigned_to_user_id==loginUser.user_id){
                            is_access = true;
                        }
                    }else {
                        is_access = true;
                    }                        
                }
            }
        }
        return is_access;
    },
    __isPageAccess(loginUser={},feature="",action="") {
        // console.log("loginUser,leadDetails,feature,action",loginUser,feature)
        let is_access = false;
        if(loginUser && Object.keys(loginUser).length > 0 && (loginUser['assigned_role_ids']?.includes(LOAN_BOX_USERS.ROLES.ADMIN.ID) || loginUser['role_id'] == parseInt(LOAN_BOX_USERS.ROLES.SUPER_ADMIN.ID))) {
            is_access = true;                        
        }else{
            const userAccessFeatures = secureStorage.getItem("userAccess");
            // console.log("userAccessFeatures====>",userAccessFeatures)            
            if(action == "config"){
                let featureActionsList = [];                
                if(userAccessFeatures && userAccessFeatures.length){
                    featureActionsList = userAccessFeatures.filter(f=>(f.feature_type == 'Config' && ['UCRF','UBRF'].includes(f.product_type)));
                }
                // console.log("featureActionsList====>",featureActionsList)
                if(featureActionsList.length > 0){
                    is_access = true;
                }
            }else{
                let featureActionsList = [];
                if(userAccessFeatures && userAccessFeatures.length){
                    featureActionsList = userAccessFeatures.map(f => f.action);
                }
                // console.log("featureActionsList====>",featureActionsList)
                if (featureActionsList.includes(feature)) {
                    is_access = true;               
                }
            }
        }

        return is_access;
    },
    __isAllowTabAccess(loginUser={},tab="my_cases"){
        let is_access = false;
        if (loginUser && (loginUser['assigned_role_ids']?.includes(LOAN_BOX_USERS.ROLES.ADMIN.ID) || loginUser['role_id'] == parseInt(LOAN_BOX_USERS.ROLES.SUPER_ADMIN.ID))) {
            is_access = true;
        }else if(tab == "my_cases"){
            for(let r of MY_CASE_ROLE_IDS){
                if(loginUser && loginUser.assigned_role_ids && loginUser.assigned_role_ids.includes(r)){
                    is_access = true;
                }
            }
        }else{
            is_access = true;
        }      
        return is_access;
    },
    __deepCopy(object){   
        return JSON.parse(JSON.stringify(object));
    },
    __convertCurrency(amount,from_currancy_type, to_currancy_type){
        let result = amount;
        if(from_currancy_type == "number" && to_currancy_type == "IDR"){
            let currancy = ![null,undefined].includes(amount) ? `${amount}` : "";
            let finalCurrency = "";
            let leftValueOfDecimal = currancy.split('.')[0];
            let rightValueOfDecimal = currancy.split('.')[1];
            if(leftValueOfDecimal){
                finalCurrency = this.__numberWithCommaSeperator(leftValueOfDecimal);
            }
            if(currancy.includes('.')){
                if(rightValueOfDecimal){
                    finalCurrency = finalCurrency + ',' + this.__numberWithCommaSeperator(rightValueOfDecimal);
                }else{
                    finalCurrency = finalCurrency + ',';
                }
            }
            result = finalCurrency;
        }else if(from_currancy_type == "IDR" && to_currancy_type == "INR"){
            let finalCurrency = amount;
            finalCurrency = finalCurrency.replaceAll(/Rp| |%|\./g,'');
            finalCurrency = finalCurrency.replaceAll(/,/g,'.');
            result = finalCurrency;
        }
        return result;
    },
    __roundValue(value,decimal_places){ 
        if(`${value}`.split('.')[1]){
            value = Number(value);
            decimal_places = Number(decimal_places);  
            return Math.round((value + Number.EPSILON) * (Math.pow(10,decimal_places))) / (Math.pow(10,decimal_places))
        }else{
            return value;
        }
    },
    __fixDecimalValue(value,decimal_places){ 
        if(`${value}`.split('.')[1]){
            decimal_places = Number(decimal_places); 
            let tempValue = `${value}`;
            return tempValue.indexOf(".") >= 0 ? tempValue.slice(0, tempValue.indexOf(".") + decimal_places+1) : tempValue;
        }else{
            return value;
        }
    },
}
export default CommonService;
